import React, {Component} from 'react';
import './Login.css';
import axios from 'axios';

class Login extends Component {
    state = {
        email: '',
        password: '',
        errorMsg: '',
        error: false,
        loading: false,
        success: false,
        successmsg: ''
    };

    addPostHandler = (event) => {
        event.preventDefault();
        const data = {
            username: this.state.email,
            password: this.state.password
        }
        this.setState({error: false, loading: true, success: false})
        axios.post('/authenticate', data).then(response => {
            console.log('LoggedIn');
            this.setState({
                loading: false
            })
            localStorage.setItem('token', response.data.token)
            window.location = "/profile";
            
        }).catch(error => {
            console.log(error);
            this.setState({
                error: true, loading: false,
                errorMsg: error.data.msg
            })
        })
    }

    gotoForgot = () => {
        this.props.history.push('/forgot')
    }


    render() {
        let onError = this.state.error ? (
            <div className="card-footer text-center text-danger">{this.state.errorMsg}</div>) : null;
        let onSuccess = this.state.success ? (
            <div className="card-footer text-center text-success">{this.state.successmsg}</div>) : null;
        let onLoading = this.state.loading ? (
            <div className="card-footer text-center text-primary">Loading...</div>) : null;

        return (
            <div className="Login">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 mobileform">
                            <form className="content inputform d-flex" onSubmit={this.addPostHandler}>
                                <h3 className="heading mb-md-5 ">Login</h3>
                                <div>
                                    <input type="email" id="emailId" className="email" placeholder="Email"
                                           pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                           value={this.state.email}
                                           onChange={(event) => this.setState({email: event.target.value})} required/>
                                    <img className="label" src="email-2.svg" alt=""/>

                                </div>
                                <div>
                                    <input value={this.state.password} minLength="4"
                                           onChange={(event) => this.setState({password: event.target.value})} required
                                           type="password" className="password" placeholder="Password"/>
                                    <img className="label" src="lock-3.svg" alt=""/>

                                </div>
                                <h6 className="text-danger" onClick={this.gotoForgot}
                                    style={{cursor: 'pointer'}}>Forgot Password?
                                </h6>
                                <div className="buttongroup mt-2">
                                    <button type="submit" className="w-100">
                                        Login
                                    </button>
                                    {onError}{onLoading}{onSuccess}
                                </div>
                                {/* <div className="mt-4 mb-4 text-center">
                                    Do not have an account? <a href="/signup">Sign up</a> now
                                </div> */}
                            </form>
                        </div>
                        <div className="col-md-6 col-sm-12 banner" style={{background: 'url(login-back.jpeg)'}}>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
