import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Axios from "axios";

var isStaging =
    window.document.location.pathname.includes("staging") ||
    window.location.protocol == "file:" ||
    window.document.location.pathname.includes("master") ||
    window.document.location.host.includes("testing") ||
    window.document.location.host.includes("localhost");

Axios.defaults.baseURL = (isStaging) ? "https://staging.joinfitapp.com/api/v1" : "https://dev.simplifii.xyz/api/v1";

Axios.interceptors.request.use(request=>{
    //console.log(request)
    return request;
},error => {
    return Promise.reject(error)
})

Axios.interceptors.response.use(response=>{
    return response;
},error => {
   // console.log(error)
    return Promise.reject(error.response)
})





ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
