import React from 'react';
import './App.css';
import AuthContainer from "./containers/authContainer/authContainer";
import {BrowserRouter} from "react-router-dom";
import Nav from "./components/Nav/Nav";
import Footer from "./components/Footer/Footer";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Nav/>
                <AuthContainer/>
                <Footer/>
            </div>
        </BrowserRouter>
    );
}

export default App;
