import React from "react";
import Login from "../../components/Login/Login";
import Signup from "../../components/Signup";
import './authContainer.css'
import {Switch, Route, Redirect} from "react-router-dom";
import Profile from "../../components/Profile/Profile";
import Macro from "../../components/Macro/Macro";
import Forgot from "../../components/Forget-Password/Forgot";

class AuthContainer extends React.Component {
    render() {
        return (<div className="AuthContainer">
            <Switch>
                { !localStorage.getItem("token") && 
                    <Route path="/login" component={Login} />
                }

                
                
                {/* <Route path="/signup" component={Signup}/> */}
                { localStorage.getItem("token") && 
                    <Route path="/profile" component={Profile}/>
                }
                <Route path="/macros" component={Macro}/>
                <Route path="/forgot" component={Forgot}/>
                { !localStorage.getItem("token") && 
                    <Redirect from="*" to="/login"/>
                }
                { localStorage.getItem("token") && 
                    <Redirect from="*" to="/profile"/>
                }
            </Switch>
        </div>)
    }

}

export default AuthContainer
