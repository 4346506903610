import React from 'react';
import {Link} from "react-router-dom";
import './Nav.css';

const Nav = () => (
    <nav className="navbar navbar-expand-lg navbar-light border-bottom mx-auto">
        <span className="navbar-brand" href="#">
            <img src="logo gradient.png" alt=""/></span>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse " id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <a href="https://joinfitapp.com/" className="nav-link">Home</a>
                </li>
                
                {/* <li className="nav-item">
                    <a className="nav-link">Become a Trainer</a>
                </li>

                <li className="nav-item">
                    <a src="" className="nav-link">About Us</a>
                </li> */}

                <li className="nav-item">
                    <a href="https://joinfitapp.com/contactus" className="nav-link">Contact Us</a>
                </li>
                <li className="nav-item">
                    
                    { !localStorage.getItem('token') &&  
                        <Link to="/login" className="nav-link">Login
                            <span className="sr-only">(current)</span>
                        </Link>
                    }

                    { localStorage.getItem('token') &&  
                        <a className="nav-link" onClick={() => { localStorage.setItem("token", ""); window.location="/login"; } }>Log out
                            <span className="sr-only">(current)</span>
                        </a>
                    }
                </li>

            </ul>

        </div>
    </nav>
);

export default Nav;
