import React, {Component} from 'react';
import './Macro.css';
import axios from 'axios';

class Macro extends Component {
    state = {
        email: '',
        password: '',
        errorMsg: '',
        error: false,
        loading: false,
        success: false,
        successmsg: ''
    };

    addPostHandler = (event) => {
        event.preventDefault();
        const data = {
            username: this.state.email,
            password: this.state.password
        }
        this.setState({error: false, loading: true, success: false})
        axios.post('/authenticate', data).then(response => {
            console.log('LoggedIn');
            this.setState({
                loading: false
            })
            localStorage.setItem('token', response.data.token)
            this.props.history.push("/profile");
        }).catch(error => {
            console.log(error);
            this.setState({
                error: true, loading: false,
                errorMsg: error.data.msg
            })
        })
    }

    forgetPasswordApiHandler = () => {
        const data = {
            username: this.state.email
        }
        this.setState({error: false, loading: true, success: false})
        axios.patch('/users/profilesitory', {
            "age": 10,
            "weight": 10,
            "height": 10,
            "gender": "Male",
            "goal": "Build muscle",
            "activity_level": "Not active",
            "meals_per_day": 10,
            "calculate_macros": true
        }).then(response => {
            console.log(response);
            this.setState({
                loading: false,
                success: true,
                successmsg: response.data.msg
            })
        }).catch(error => {
            console.log(error);
            this.setState({
                error: true, loading: false,
                errorMsg: error.data.msg
            })
        })
    }

    forgetPasswordHandler = () => {
        const email = document.getElementById("emailId");
        console.log(email.checkValidity())
        if (email.checkValidity()) {
            this.forgetPasswordApiHandler();
        } else {
            this.setState({
                error: true, loading: false,
                errorMsg: "Valid Email is required"
            })
        }
    }

    render() {
        // let onError = this.state.error ? (
        //     <div className="card-footer text-center text-danger">{this.state.errorMsg}</div>) : null;
        // let onSuccess = this.state.success ? (
        //     <div className="card-footer text-center text-success">{this.state.successmsg}</div>) : null;
        // let onLoading = this.state.loading ? (
        //     <div className="card-footer text-center text-primary">Loading...</div>) : null;

        return (
            <div className="Macro">
                <section id="section1">
                    <div className="container ">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <h4 className="heading bold">
                                    Macro Calculator
                                </h4>
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                                    Maiores atque animi soluta veritatis aspernatur voluptatem suscipit vitae
                                    perspiciatis
                                    excepturi voluptates necessitatibus quisquam, aliquam neque.
                                    Molestias molestiae fugit eveniet enim a!</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="information" className=" w-75 mx-auto">
                    <h1>Enter your information</h1>
                    <div className="card bg-white shadow-sm px-5 pt-5 pb-5">
                        <div className="section1 w-100">
                            <h2>Basic Information</h2>
                            <div className="row">
                                <div className="col-md-2"><h3>Age</h3></div>
                                <div className="col-md-4"><h3>Gender</h3></div>
                                <div className="col-md-3"><h3>Weight</h3></div>
                                <div className="col-md-3"><h3>Height</h3></div>
                            </div>
                            <div className="row">
                                <div className="col-md-2"><input className="input-form back-color"/></div>
                                <div className="col-md-4 row">
                                    <div className="input-place col">Male <input type="radio"/></div>
                                    <div className="input-place col">Female <input type="radio"/></div>
                                </div>
                                <div className="col-md-3 text-right">
                                    <div className="input-place"><input className="input-borderless"
                                                                        style={{width: '6rem'}} type="text"/> <span
                                        className="border mr-1">lb</span><span className="border">kg</span></div>
                                </div>
                                <div className="col-md-3 text-right">
                                    <div className="input-place"><input className="input-borderless" type="text"/> <span
                                        className="border border1 mr-1">ft</span><span
                                        className="border border1 mr-1">in</span><span
                                        className="border border1">cm</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="section2 w-100">
                            <h2>Your Level Of Activity</h2>
                            <div className="row w-100">
                                <div className="col">
                                    <div className="input-place col"><h4>Not active</h4>
                                        <input type="radio"/>
                                        <p>Lorem ipsum dolor sit amet ipsum dolor sit amet .</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-place col"><h4>Work standing or active job</h4>
                                        <input type="radio"/>
                                        <p>Lorem ipsum dolor sit amet ipsum dolor sit amet .</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-place col"><h4>Workout from time to time</h4>
                                        <input type="radio"/>
                                        <p>Lorem ipsum dolor sit amet ipsum dolor sit amet .</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-place col"><h4>Workout Regularly</h4>
                                        <input type="radio"/>
                                        <p>Lorem ipsum dolor sit amet ipsum dolor sit amet .</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-place col"><h4>An athlete</h4>
                                        <input type="radio"/>
                                        <p>Lorem ipsum dolor sit amet ipsum dolor sit amet .</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section2 w-100">
                            <h2>Your Fitness Goal</h2>
                            <div className="row w-100">
                                <div className="col">
                                    <div className="input-place col"><h4>Lose Fat</h4>
                                        <input type="radio"/>
                                        <p>Lorem ipsum dolor sit amet ipsum dolor sit amet .</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-place col"><h4>Gain Muscle</h4>
                                        <input type="radio"/>
                                        <p>Lorem ipsum dolor sit amet ipsum dolor sit amet .</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-place col"><h4>Maintain</h4>
                                        <input type="radio"/>
                                        <p>Lorem ipsum dolor sit amet ipsum dolor sit amet .</p>
                                    </div>
                                </div>
                                <div className="col">
                                </div>
                                <div className="col">
                                </div>
                            </div>
                        </div>
                        <div className="section2 w-100">
                            <h2>Chose Diet</h2>
                            <div className="row w-100">
                                <div className="col">
                                    <div className="input-place col"><h4>General
                                        Health </h4>
                                        <input type="radio"/>
                                        <p>Lorem ipsum dolor sit amet ipsum dolor sit amet .</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-place col"><h4>HighCarb Muscle Building</h4>
                                        <input type="radio"/>
                                        <p>Lorem ipsum dolor sit amet ipsum dolor sit amet .</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-place col"><h4>Ketotarian</h4>
                                        <input type="radio"/>
                                        <p>Lorem ipsum dolor sit amet ipsum dolor sit amet .</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="input-place col"><h4>Mod-Carb Paleo</h4>
                                        <input type="radio"/>
                                        <p>Lorem ipsum dolor sit amet ipsum dolor sit amet .</p>
                                    </div>
                                </div>
                                <div className="col">
                                </div>
                            </div>
                        </div>
                        <button className="Button" onClick={this.forgetPasswordApiHandler}>Calculate</button>
                    </div>
                </section>
                <section id="section2">
                    <div className="container pt-5">
                        <div className="row">
                            <div className="col">
                                <div className="container mb-3">
                                    <div className="row">
                                        <div className="col-md-6"><h3>Results</h3></div>
                                        <div className="col-md-6">
                                            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="pills-home-tab"
                                                       data-toggle="pill" href="#pills-home"
                                                       role="tab" aria-controls="pills-home" aria-selected="true">Per
                                                        Meal</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="pills-profile-tab" data-toggle="pill"
                                                       href="#pills-profile"
                                                       role="tab" aria-controls="pills-profile" aria-selected="false">Per
                                                        Day</a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                                         aria-labelledby="pills-home-tab">
                                        <div className="container card">
                                            <div className="row">
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="circle"
                                                         style={{borderColor:"#ff531e !important",background:"#fff0e8"}}>
                                                        <img src="./464.png" alt=""/>
                                                            <h6 className="heading" style={{color:"#ff531e"}}>500</h6>
                                                            <p style={{color:"#ff531e"}}>ccal</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="circle"
                                                         style={{borderColor:"#ffe1b7 !important",background:"#fffbf6"}}>
                                                        <img src="./471.png" alt=""/>
                                                            <h6 className="heading" style={{color:"#ffe1b7"}}>45g</h6>
                                                            <p style={{color:"#ffe1b7"}}>carbs</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="circle"
                                                         style={{borderColor:"#0092b7 !important",background:"#ecf8fa"}}>
                                                        <img src="./478.png" alt=""/>
                                                            <h6 className="heading" style={{color:"#0092b7"}}>50g</h6>
                                                            <p style={{color:"#0092b7"}}>protein</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="circle"
                                                         style={{borderColor:"#a6d7ba  !important",background:"#f5faf6"}}>
                                                        <img src="./485.png" alt=""/>
                                                            <h6 className="heading" style={{color:"#a6d7ba"}}>10g</h6>
                                                            <p style={{color:"#a6d7ba"}}>fat</p>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                                         aria-labelledby="pills-profile-tab">Per Day
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="section3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="prefooter py-md-5">
                                    <h3>Get results to your email and subscribe to our newsletter</h3>
                                    <input type="email" placeholder="Email"/>
                                        <button>Get results</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Macro;
