import React, {Component} from 'react';
import axios from "axios";
import './Profile.css';

class Profile extends Component {
    state = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        errorMsg: '',
        error: false,
        loading: false,
        token: '',
        success: false,
        successmsg: '',
        courses: [],
        cancel_id: null,
        all_access: "none"
    };

    componentDidMount = () => {
        this.getAllAccess();
    }

    getCourseList = () => {
        let token = this.getToken();
        axios.patch('/custom/coursesandchallenges?token=' + token)
        .then(response => {            
            const courses = response.data.response.courses.filter(course => (this.state.all_access !== "none" || course.is_purchased));
            this.setState({
                courses: courses
            });

            this.postAppOpenEvent();
        })
    }

    getAllAccess = () => {
        let token = this.getToken();
        this.setState({token: token})
        axios.patch('/custom/hasallaccess?token=' + token)
        .then(response => {
            this.setState({ all_access: response.data.response.all_access });
            this.getCourseList();
        });
    }

    postAppOpenEvent = () => {
        let token = this.getToken();

        axios.post('/fitevents?token=' + token, {
            type: "app_open_web",
            title: "Application Opened by user"
        })
        .then(response => {
            console.log(response);
        })
    }

    getToken() {
        const urlParams = new URLSearchParams(this.props.location.search);
        var token = localStorage.getItem('token');
        if (urlParams.has('token')) {
            token = urlParams.get('token');
            localStorage.setItem('token', token);
        }
        return token;
    }

    addPostHandler = (event) => {
        event.preventDefault();
        const data = {
            "new_password": this.state.newPassword
        }
        this.setState({
            error: false, loading: true,
            success: false
        })
        axios.patch('/change_password?token=' + this.state.token, data).then(response => {
            console.log(response.data.msg);
            //alert(response.data.msg);
            this.setState({
                loading: false,
                success: true,
                successmsg: response.data.msg
            })
        }).catch(error => {
            console.log(error);
            this.setState({
                error: true, loading: false,
                errorMsg: error.data.msg
            })
        })
    };

    cancelSubHandler = () => {
        const data = {
            "subscription_id": this.state.cancel_id
        }
        this.setState({
            error: false, loading: true,
            success: false
        })
        axios.patch('/subscriptions/cancel?token=' + this.state.token, data).then(response => {
            console.log(response.data.msg);
            //  alert(response.data.msg);
            axios.get('/subscriptions?token=' + this.state.token).then(value => {
                console.log(value.data.response.data);
                this.setState({subscriptions: value.data.response.data})
            })
            this.setState({
                loading: false,
                success: true,
                successmsg: response.data.msg
            })
        }).catch(error => {
            console.log(error);
            this.setState({
                error: true, loading: false,
                errorMsg: error.data.msg
            })
        })
    };
    confirmPasswordHandler = (event) => {
        this.setState({
            confirmPassword: event.target.value
        });
        const password = document.getElementById("new-password");
        const confirm_password = document.getElementById("confirm-password");
        if (password.value !== confirm_password.value) {
            confirm_password.setCustomValidity("Passwords Don't Match")
        } else {
            confirm_password.setCustomValidity('')
        }
    }

    render() {
        let onError = this.state.error ? (
            <div className="card-footer text-center text-danger">{this.state.errorMsg}</div>) : null;
        let onLoading = this.state.loading ? (
            <div className="card-footer text-center text-primary">Loading...</div>) : null;
        let onSuccess = this.state.success ? (
            <div className="card-footer text-center text-success">{this.state.successmsg}</div>) : null;

        return (
            <span>
                <div className="container ProfilePage">
                    <div className="row py-md-5 md-margin">
                        <div className="col">
                            <h2 className="heading2">
                                Profile settings
                            </h2>
                        </div>
                    </div>
                    <div className="row mb-md-2">
                        <div className="col">
                            <h3 className="heading3">
                                Change passwords
                            </h3>
                        </div>
                    </div>
                    <form className="row inputform" style={{margin: '0px'}} onSubmit={this.addPostHandler}>
                        <input className="changeinput" placeholder="Current password" type="password"
                            value={this.state.currentPassword}
                            onChange={(event) => this.setState({currentPassword: event.target.value})} required/>
                        <input className="changeinput" placeholder="New password" type="password"
                            value={this.state.newPassword} minLength="4"
                            id="new-password"
                            onChange={(event) => this.setState({newPassword: event.target.value})} required/>
                        <input className="changeinput" placeholder="Repeat new password" type="password"
                            value={this.state.confirmPassword}
                            minLength="4"
                            id="confirm-password"
                            onChange={this.confirmPasswordHandler} required/>
                        <button type="submit">Submit</button>
                    </form>

                    <div>{onError}{onLoading}{onSuccess}</div>
                    
                    {/*Modal*/}
                    <div className="modal fade" id="myModal" role="dialog">
                        <div className="modal-dialog">

                            {/*Modal content*/}
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    <h4 className="modal-title">Do you want to cancel this subscription</h4>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.cancelSubHandler}>Yes</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row pt-5">
                        <div className="col">
                            <h4 className="heading4">
                                Your subscription(s)
                            </h4>
                        </div>
                    </div>
                    {this.state.courses.map((value, key) => {

                        return <div key={key}>
                            <div className="row mb-4">
                                <div className="col-md-6 col-sm-12 sub-back py-3 pl-3"
                                    style={{background: `url(${value.photo})`}}
                                    onClick={() => window.location = `/workouts?course_id=${value.course_id}`}>
                                    <h3 className="heading5">
                                        {value.title}

                                    </h3>
                                    <h2 className="heading6">
                                        { value.description } &nbsp;
                                        {/* {value.msg}<br/>
                                        Status: {value.status} */}
                                    </h2>
                                </div>
                            </div>
                            {/* <div className="row py-3">
                                <div className="col">
                                    <h6 style={{color: '#5c5d5f', cursor: 'pointer'}} data-toggle="modal"
                                        data-target="#myModal" onClick={()=>{this.setState({cancel_id:value.id})}} >
                                        Click here to cancel Subscription
                                    </h6>
                                </div>
                            </div> */}
                        </div>
                    })}
                    <div>
                    </div>
                </div>
            </span>
        );
    }
}

export default Profile;
