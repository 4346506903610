import React, {Component} from 'react';
import './Forgot.css';
import axios from 'axios';

class Forgot extends Component {
    state = {
        email: '',
        password: '',
        errorMsg: '',
        error: false,
        loading: false,
        success: false,
        successmsg: ''
    };

    forgetPasswordApiHandler = (event) => {
        event.preventDefault();
        const data = {
            username: this.state.email
        }
        this.setState({error: false, loading: true, success: false})
        axios.patch('/reset_password', data).then(response => {
            console.log(response);
            this.setState({
                loading: false,
                success: true,
                successmsg: response.data.msg
            })
        }).catch(error => {
            console.log(error);
            this.setState({
                error: true, loading: false,
                errorMsg: error.data.msg
            })
        })
    }

    backToLogin = () => {
        this.props.history.push('/login');
    }

    render() {
        let onError = this.state.error ? (
            <div className="card-footer text-center text-danger">{this.state.errorMsg}</div>) : null;
        let onSuccess = this.state.success ? (
            <div className="card-footer text-center text-success">{this.state.successmsg}</div>) : null;
        let onLoading = this.state.loading ? (
            <div className="card-footer text-center text-primary">Loading...</div>) : null;

        return (
            <div className="Forgot">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 mobileform">
                            <form className="content inputform d-flex" onSubmit={this.forgetPasswordApiHandler}>
                                <h3 className="heading mb-md-5 ">Forgot your password?</h3>
                                <div>
                                    <input type="email" id="emailId" className="email" placeholder="Email"
                                           pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                           value={this.state.email}
                                           onChange={(event) => this.setState({email: event.target.value})} required/>
                                    <img className="label" src="email-2.svg" alt=""/>

                                </div>
                                <h6 className="text-danger" onClick={this.backToLogin}
                                    style={{cursor: 'pointer'}}>&#8592; Back to Login Screen
                                </h6>
                                <div className="buttongroup mt-2">
                                    <button type="submit">
                                        Submit
                                    </button>

                                    {onError}{onLoading}{onSuccess}
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 col-sm-12 banner" style={{background: 'url(login-back.jpeg)'}}>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Forgot;
