import React from 'react';
import {Link} from "react-router-dom";
import './Footer.css';

const Footer = (props) => (
    <footer>
        <div className="container">
            <div className="row">
                <div className="col-md-6 d-flex contentfooter justify-content-space-around">
                    <h3 className="text-center">Privacy Policy &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Terms of
                        use
                    </h3>
                </div>
                <div className="col-md-6">
                    <h3>
                        © Copyright 2019-Fit!
                    </h3>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
